html {
    background-color: #ffffff;
    -ms-overflow-style: none;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

/* .ql-doc */

/* .ql-doc:before {
    font-family: 'Material Icons';
    content: '\e415';
    font-size: 18px
} */

/* .ql-reference:before { */
/* font-family: 'Material Icons'; */
/* content: 'Reference'; */
/* font-size: 18px */
/* } */

.ql-price i {
    font-size: 18px;
}

.ql-priceContent {
    color: #122632;
    width: fit-content;
    background-color: white;
    background-color: #fafafa;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    padding: 10px 16px;
    margin: 0 0px 15px;
}

.ql-editor li:not(.ql-direction-rtl)::before {
    margin-left: 0;
    margin-right: 0.3em !important;
    /* text-align: 0 */
}

.ql-container .ql-editor ol, .ql-container .ql-editor ul {
    padding-left: 2.5em;
}

.ql-editor ul > li {
    list-style: disc !important;
}

.ql-editor ol > li,
.ql-editor ul > li {
    /* padding-left: 0 !important; */
}

.ql-editor ul > li::before {
    width: 9px;
    content: none !important;
}

.ql-editor blockquote {
    font-style: italic;
}

.portal ul > li::before {
    margin-left: 6px !important;
    margin-right: 18px !important;
    width: 0 !important;
}

.CodeMirror {
    height: auto !important;
    overflow-x: auto !important;
}

.CodeMirror-scroll {
    height: auto !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
}